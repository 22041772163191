import { clientController } from "../../../cdm/controllers/client-controller";
import { stateController } from "../../../cdm/controllers/state-controller";
import { action, autorun, computed, observable, toJS, when } from "mobx";
import {
  billingEnabledGroupTypeIds,
  groupTypeIds,
  profilePrototype,
  RoleGroupGroupTypeIds,
  serviceRecipientGroupTypeIds
} from "../../../config/variable-config";
import { UIText } from "../../../config/lang-config";
import {
  capitalize,
  contextReject,
  isEmpty,
  preventDefaultStopProp,
  randomString,
  whenFulfill
} from "../../../utils/helpers";
import { apiController } from "../../../cdm/controllers/api-controller";
import { endpointConfig } from "../../../config/api-config";
import {
  addLocationFieldToProfile,
  isNewBillingTypeGroup,
  isOwnerOrPrimaryCaregiverOrSupport,
  paidCaregiverFeatureRestricted
} from "../../../custom/mcb/lib/group-utilities-mcb";
import {
  checkGroupSubscription,
  handleGroupBottomTabChange,
  subscriptionPopup
} from "../../../cdm/lib/group-utilities";
import { isSnSOrAdmin } from "../../../custom/mcb/utils/helper";
import { embeddedService } from "../../../cdm/services/embedded-service";
import { Linking, Platform } from "react-native";
import { msgService } from "../../../cdm/services/messaging-service";

export class GroupController {
  @observable loading = false;
  @observable chatLoading = false;
  @observable _subscriptionReady = false;

  @computed get mCBGroupUseLegacyMarketplace() {
    return clientController.client.mCBGroupUseLegacyMarketplace;
  }

  autoTopTabs = {};

  get bottomTabs() {
    return [
      (!this.isScratchpad || this.isScratchpadSub) && {
        key: "dashboard",
        icon: "dashboard",
        label: UIText.groupTabDashboard
      },
      !this.isRoleGroup &&
        this.isCareReceiverGroup &&
        (!this.isScratchpad || this.isScratchpadSub) &&
        !paidCaregiverFeatureRestricted(this.group, this.selfMember) && {
          // this.currentPlan.isCareEnabled && {
          key: "shift",
          icon: "assignment",
          disabled: !this.currentPlan.isCareEnabled,
          disabledBySub: !this.currentPlan.isCareEnabled,
          label: capitalize(UIText.shiftMaker)
        },
      !this.isRoleGroup &&
        (!this.isScratchpad || this.isScratchpadSub) && {
          // this.currentPlan.isCareEnabled && {
          key: "members",
          icon: "people",
          label: UIText.groupMemberWording.plural
        },
      // !this.isRoleGroup && this.isMCBGroup &&
      // !paidCaregiverFeatureRestricted(this.group, this.selfMember) && {
      //   // (this.currentPlan.isCareEnabled || this.isScratchpad) && {
      //   key: "marketplace",
      //   icon: "shopping-cart",
      //   label: capitalize(UIText.marketplace)
      // },
      !this.isRoleGroup &&
        !paidCaregiverFeatureRestricted(this.group, this.selfMember) && {
          // (this.currentPlan.isCareEnabled || this.isScratchpad) && {
          key: "finder",
          icon: "shopping-cart",
          label: capitalize(UIText.marketplace)
        }
    ].filter(Boolean);
  }

  @computed get user() {
    return clientController.client.user;
  }
  @computed get isScratchpad() {
    return (
      !!this.group.groupName && this.group.groupName.match(/{scratchpad}/g)
    );
  }
  @computed get isScratchpadSub() {
    return (
      !!this.group.groupName &&
      this.group.groupName.match(/{scratchpad}/g) &&
      !isEmpty(this.subscription)
    );
  }
  @computed get isVisitor() {
    return clientController.isVisitor;
  }
  @computed get isMCBGroup() {
    return this.group.typeId === groupTypeIds.myCareBaseStaff;
  }
  @computed get isCareReceiverGroup() {
    return this.group.typeId === groupTypeIds.careReceiver;
  }
  @computed get currentBottomTab() {
    let autoPage;
    if (!stateController.viewGroupState.autoPageParamUsed) {
      const autoPageParam = this.props.navigation.getParam("page");

      if (autoPageParam && !this.isVisitor && !this.isScratchpad) {
        autoPage =
          this.bottomTabs.find(t => t.key === autoPageParam) && autoPageParam;
      }
      setTimeout(
        () => (stateController.viewGroupState.autoPageParamUsed = true)
      );
    }

    !stateController.viewGroupState.bottomTabs[this.groupId] &&
      handleGroupBottomTabChange(
        this.groupId,
        this.isScratchpad
          ? // ? "marketplace"
            "finder"
          : autoPage
          ? autoPage
          : "dashboard"
      );

    const page = stateController.viewGroupState.bottomTabs[this.groupId];
    setTimeout(
      () =>
        stateController.currentScreen === "Group" &&
        !this.loading &&
        this.subscriptionReady &&
        this.props.navigation.navigate("Group", { page })
    );
    return page;
  }
  @computed get searchBarVisible() {
    if (!stateController.viewGroupState.searchBar[this.groupId]) {
      stateController.viewGroupState.searchBar[this.groupId] = {};
    }
    return stateController.viewGroupState.searchBar[this.groupId]["visible"];
  }
  @computed get groups() {
    return clientController.findVisibleGroups(true); // includeScratchpad
  }
  @computed get groupId() {
    return stateController.viewGroupId;
  }
  @computed get group() {
    return clientController.findGroupById(this.groupId);
  }
  @computed get members() {
    return this.group.members || [];
  }
  @computed get selfMember() {
    return this.members.find(m => m.userId === clientController.userId) || {};
  }
  @computed get profile() {
    return (
      (!isEmpty(this.group.profile) && this.group.profile) || profilePrototype
    );
  }
  @computed get myRole() {
    const roles = (Array.isArray(this.group.roles) && this.group.roles) || [];
    return (
      roles.find(r => {
        return (
          Array.isArray(r.members) &&
          r.members.find(m => m.userId === clientController.userId)
        );
      }) || {}
    );
  }
  @computed get chatTopic() {
    return msgService.findChatLobbyTopic(this.groupId);
  }
  @computed get messagesCount() {
    const groupUnreadCountData = (msgService.unreadCountTopics || []).find(
      t => t.id === this.chatTopic.id
    );
    return groupUnreadCountData && groupUnreadCountData.unreadCount;
  }
  @computed get isRoleGroup() {
    return RoleGroupGroupTypeIds.includes(this.group.typeId);
  }
  @computed get subscription() {
    return this.group.subscription || this.group.wpSubscription || {};
  }
  @computed get subscriptionReady() {
    return clientController.subscriptionReady && this._subscriptionReady;
  }
  @computed get currentPlan() {
    return this.subscription.plan || {};
  }
  @computed get headerShowAppointment() {
    return true;
  }
  @computed get headerShowBilling() {
    return (
      (serviceRecipientGroupTypeIds.includes(this.group.typeId) ||
        billingEnabledGroupTypeIds.includes(this.group.typeId)) &&
      !paidCaregiverFeatureRestricted(this.group, this.selfMember)
    );
  }
  @computed get headerShowEdit() {
    return (
      !serviceRecipientGroupTypeIds.includes(this.group.typeId) ||
      // (!isEmpty(this.subscription) &&
      !paidCaregiverFeatureRestricted(this.group, this.selfMember)
    );
  }
  @computed get headerShowLeftIcon() {
    return this.isVisitor
      ? embeddedService.isEmbedded
        ? undefined
        : "exit-to-app"
      : "menu";
  }
  @computed get onboarding() {
    return stateController.onboardingSignIn && this.isRoleGroup;
  }
  @computed get editProfile() {
    return Number(this.props.navigation.getParam("editProfile")) === 1;
  }

  constructor(props) {
    this.props = props;
    this._initialize();
  }

  componentDidMount() {
    stateController.currentScreen = "Group";
    this._didFocusSubscription = this.props.navigation.addListener(
      "didFocus",
      this._getGroups
    );
  }

  componentWillUnmount() {
    this._didFocusSubscription.remove();
    Array.isArray(this.disposers) &&
      this.disposers.map(disposer => disposer && disposer());
  }

  _showError = err => {
    console.warn(err);
    stateController.showPopup({
      title: capitalize(UIText.group),
      content:
        (err.response && JSON.stringify(err.response.data).replace(/"/g, "")) ||
        err.message,
      leftButtonText: UIText.generalConfirm,
      dismissOnBackPress: true
    });
  };

  _initialize = () => {
    return this._getGroups()
      .then(
        () =>
          (this.disposers = [
            autorun(this._getGroup),
            autorun(this._getGroupSubscription),
            autorun(this._getGroupProfile),
            autorun(this._groupExistenceMonitor),
            autorun(this._checkCaregiverQuestionnaire),
            autorun(() => this.getGroupMembers()),
            autorun(() => this.getChatTopic())
          ])
      )
      .then(() => {
        this.autoTopTabs = {
          main: this.props.navigation.getParam("mainTab"),
          shift: this.props.navigation.getParam("shiftTab"),
          market: this.props.navigation.getParam("marketTab")
        };
      })
      .then(() => {
        if (this.onboarding || this.editProfile) {
          stateController.viewProfileState.autoEdit = true;
          stateController.onboardingSignIn = false; // Onboarding ends here.
          when(
            () =>
              !isEmpty(this.group) &&
              !!this.group.profileId &&
              stateController.viewGroupState.autoPageParamUsed,
            () => {
              if (this.editProfile)
                this.props.navigation.setParams({ editProfile: undefined });
              setTimeout(
                () => this.handleHeadingPress(null, this.onboarding),
                500
              );
            }
          );
        }
      });
  };

  _getGroups = async () => {
    if (!stateController.viewGroupId && clientController.loginState)
      stateController.viewGroupId =
        Number(this.props.navigation.getParam("group")) ||
        (this.groups[0] && this.groups[0].id);

    const illegalMCBGroup = this.isMCBGroup && !isSnSOrAdmin(this.user);
    const newAccount = !this.isVisitor && this.groups.length === 0;
    const invalidGroupId = !this.groups.find(
      group => group.id === this.groupId
    );
    const invalidScratchpad =
      !this.isVisitor &&
      this.isScratchpad &&
      !this.isScratchpadSub &&
      clientController.scratchpadId !== this.groupId;
    const isVisitorNotScratchpad = !this.isScratchpad && !!this.isVisitor;

    // console.log(newAccount, invalidGroupId, invalidScratchpad, isVisitorNotScratchpad);

    if (
      illegalMCBGroup ||
      newAccount ||
      invalidGroupId ||
      invalidScratchpad ||
      isVisitorNotScratchpad
    ) {
      this.loading = true;
      stateController.viewGroupId = 0;
      return this.props.navigation.navigate("Root");
    }

    return Promise.resolve();
  };

  _getGroup = async () =>
    apiController.getGroupById(this.groupId).then(group => {
      if (
        (isEmpty(group) || isEmpty(this.selfMember)) &&
        clientController.initialized &&
        clientController.loginState
      )
        return clientController.removeGroup(this.groupId);
    });

  _getGroupSubscription = async () => {
    if (isEmpty(this.group)) return;

    if (!billingEnabledGroupTypeIds.includes(this.group.typeId)) {
      this._subscriptionReady = true;
      return Promise.resolve();
    }

    this._subscriptionReady = false;

    return await checkGroupSubscription(this.groupId)
      .then(this._checkSubscription)
      .finally(() => (this._subscriptionReady = true));
  };

  _checkSubscription = async () => {
    if (this.isScratchpad) {
      if (this.isScratchpadSub) {
        // An unfinished scratchpad conversion.
        stateController.initSetupMode = "finishScratchpad";
        return this.props.navigation.navigate("Setup");
      }
      return Promise.resolve();
    }

    const minimumPopupDays = 7;

    if (isEmpty(this.subscription)) {
      // Group with no sub.
      if (!stateController.viewGroupState.subscriptionChecked[this.groupId]) {
        // return this.handleBillingPress();
        // subscriptionPopup(this.group["groupTypeName"]);
        return (stateController.viewGroupState.subscriptionChecked[
          this.groupId
        ] = true);
      } else {
        // return stateController.showPopup({
        //   title: capitalize(UIText.group),
        //   content: UIText.planNoFeature(this.group["groupTypeName"]),
        //   leftButtonText: UIText.planSubscribeNow,
        //   rightButtonText: UIText.generalConfirm,
        //   leftButtonPress: () =>
        //     stateController.dismissPopup().then(this.handleBillingPress)
        // });
      }
    } else if (
      // Has any kinds of remaining days (promo, trial).
      this.subscription.userId === clientController.userId &&
      this.subscription["planStatus"] === "trialing" &&
      this.subscription.plan["remainingDays"] &&
      Number(this.subscription.plan["remainingDays"]) <= minimumPopupDays
    ) {
      return (
        !stateController.getPopupLock() &&
        stateController.dismissPopup().then(() =>
          stateController.showPopup({
            title: capitalize(UIText.group),
            content: UIText.planTrialRemainingPopup(
              this.subscription.plan["remainingDays"].toString(),
              !!this.subscription["promoId"]
            ),
            leftButtonText: UIText.generalConfirm,
            dismissOnBackPress: true
          })
        )
      );
    }
  };

  _getGroupProfile = async () => {
    if (isEmpty(this.group)) return;
    if (!this.group.profileId) return;

    if (isEmpty(this.group.profile)) {
      return apiController
        .getProfileById(this.group.profileId)
        .then(profile => {
          clientController.updateProfile(profile);
        })
        .catch(this._showError);
    }
    return Promise.resolve();
  };

  _groupExistenceMonitor = () => {
    if (
      isEmpty(this.group) &&
      clientController.initialized &&
      clientController.loginState
    ) {
      const group = clientController.findGroupById(this.groupId); // Assurance.
      if (isEmpty(group)) {
        Array.isArray(this.disposers) &&
          this.disposers.map(disposer => disposer());
        this.props.navigation.navigate("Root");
      }
    }
  };

  _checkCaregiverQuestionnaire = async () => {
    const isCaregiver = this.group.typeId === groupTypeIds.caregiver;
    if (!isCaregiver) return;
    if (stateController.onboardingProfileClicked || this.onboarding) return;
    // const onboarding = await apiService
    //   .async("GET", {
    //     endpoint: `${mcbEndpointConfig.caregiver_onboarding}?tokenData=true`
    //   })
    //   .then(response => response.data);
    // const profileData = onboarding.profileData;
    // const isApproved =
    //   profileData && !!profileData["approvalOfCandidateConfirmedByReference"];
    // const screeningDates = ((onboarding || {}).onboarding || {})
    //   .screeningStatusDates;
    // const questionnaireCompleted = (screeningDates || []).some(
    //   d => d.screeningStatus === "questionnaire completed"
    // );
    // if (
    //   !isEmpty(onboarding) &&
    //   (!questionnaireCompleted || !isApproved)
    // )
    this.handleHeadingPress();
  };

  getGroupMembers = force => {
    if (!clientController.loginState) return;
    if (isEmpty(this.group)) return;

    const getMembers = async () =>
      apiController
        .getMembersByGroupId(stateController.viewGroupId)
        .then(
          action(async members => {
            if (!members) return;

            for (let member of members) {
              if (isEmpty(member.profile)) {
                member.profile = await apiController
                  .getProfileById(member.profileId)
                  .catch(contextReject);
              }
              clientController.updateMember(member);
            }
            return Promise.resolve();
          })
        )
        .catch(this._showError);

    if (force) return getMembers();

    const needRefreshMember =
      isEmpty(this.group.members) ||
      (this.group.members || []).some(m => isEmpty((m.profile || {}).data));
    if (needRefreshMember) return getMembers();

    return Promise.resolve();
  };

  getChatTopic = async noDebounce => {
    if (isEmpty(this.group)) return;
    const getChatTopic = async () => {
      if (this.isRoleGroup) return;
      if (!this.groupId) return;
      if (isEmpty(this.group)) return;

      this.chatLoading = true;
      if (isEmpty(this.chatTopic)) {
        return apiController
          .groupChatLobbyPreJoin(this.groupId)
          .then(topic => {
            clientController.updateTopic(topic);
            this.chatLoading = false;
          })
          .catch(this._showError);
      }
      this.chatLoading = false;
      return Promise.resolve();
    };
    if (noDebounce) {
      return getChatTopic();
    } else {
      clearTimeout(this.getChatTopicDebounce);
      this.getChatTopicDebounce = setTimeout(getChatTopic, 300);
    }
  };

  handleBottomTabChange = tab => {
    if (tab.disabled) {
      return tab.disabledBySub && subscriptionPopup(null, tab.label);
    }
    stateController.viewGroupState.previousBottomTabs[this.groupId] = toJS(
      this.currentBottomTab
    );

    handleGroupBottomTabChange(this.groupId, tab.key);

    if (tab.onPress) tab.onPress();
  };

  handleHeadingPress = (event, onboarding) => {
    preventDefaultStopProp(event);

    const id = this.group.profileId;
    const screenId = randomString();

    const canEdit =
      isOwnerOrPrimaryCaregiverOrSupport(this.group, this.selfMember) ||
      !serviceRecipientGroupTypeIds.includes(this.group.typeId);

    const isCaregiver = this.group.typeId === groupTypeIds.caregiver;

    if (isCaregiver) {
      // stateController.viewProfileState.profilePostSaveProcessors[screenId] = [
      //   typeClassLibrary.caregiverSaveProfileReminder
      // ];
      // stateController.viewProfileState.additionalProfileProcessors[screenId] = [
      //   profileForm => addAvailabilityFormToProfile(profileForm, screenId)
      // ];
      // stateController.viewProfileState.additionalProfileValidators[screenId] = [
      //   validateAvailabilityForm
      // ];
      return this.props.navigation.navigate("Wizard", {
        group: this.groupId
      });
    } else {
      stateController.viewProfileState.additionalProfileProcessors[screenId] = [
        addLocationFieldToProfile
      ];
    }

    stateController.viewProfileState.readOnly[screenId] = !canEdit;

    stateController.viewProfileState.allowEmailEdit[screenId] = this.isMCBGroup;

    if (onboarding)
      stateController.viewProfileState.titleOverride[
        screenId
      ] = UIText.caregiverCompleteProfile(this.group.groupTypeName);

    this.props.navigation.navigate("Profile", {
      group: this.groupId,
      profile: id,
      screenId,
      editProfile: undefined
    });
  };

  handleBillingPress = event => {
    preventDefaultStopProp(event);

    stateController.viewPlanState.setupMode = false;
    const id = this.groupId;

    if (isNewBillingTypeGroup(this.group)) {
      this.props.navigation.navigate("Billing", {
        group: id
      });
    } else {
      this.props.navigation.navigate("Plan", {
        group: id
      });
    }
  };

  handleSearchPress = event => {
    preventDefaultStopProp(event);
    stateController.viewGroupState.searchBar[this.groupId].visible = !this
      .searchBarVisible;
  };

  handleAppointmentPress = event => {
    preventDefaultStopProp(event);
    const url = `https://${endpointConfig.wp_hostname}/appointments?aplistid=${
      this.groupId
    }`;
    return Platform.OS === "web" ? window.open(url) : Linking.openURL(url);
  };

  openChat = async () => {
    const topicId = this.chatTopic.id;
    if (!topicId) {
      this.chatLoading = true;
      return this.getChatTopic()
        .then(() => whenFulfill(() => !isEmpty(this.chatTopic)))
        .then(this.openChat)
        .catch(this._showError);
    }

    stateController.viewTopicId = this.chatTopic.id;
    this.props.navigation.navigate("Chat", { topic: this.chatTopic.id });
  };

  clearAutoTopTabs = page => {
    this.props.navigation.setParams({ [page + "Tab"]: null });
    this.autoTopTabs[page] = null;
  };

  toggleMCBGroupLegacyMarketplace = () =>
    (clientController.client.mCBGroupUseLegacyMarketplace = !clientController
      .client.mCBGroupUseLegacyMarketplace);

  execLogout = e => clientController.execLogout(e, true);
}
