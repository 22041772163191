import { stateController } from '../../../../../cdm/controllers/state-controller';
import { clientController } from '../../../../../cdm/controllers/client-controller';
import { groupTypeIds } from '../../../../../config/variable-config';
import { UIText } from '../../../../../config/lang-config';
import { canCreateCaregiverOrCleanerGroup } from '../../../lib/group-utilities-mcb';

export const addGroup = () => ({
  key: "addGroup",
  rootBack: {
    onPress: props => {
      props.navigation.navigate("Group");
    }
  },
  skipSelect: true,
  skipFinish: true,
  setupList: [
    {
      title: "New role or group",
      heading: "Set up a new role or group",
      checkboxTitle: "New role or group",
      form: [
        {
          name: "groupType",
          type: "picker",
          options: [
            {
              name: "careCircle",
              placeholder: clientController.findGroupTypeById(
                groupTypeIds.careReceiver
              ).typeName
            },
            {
              name: "household",
              placeholder: clientController.findGroupTypeById(
                groupTypeIds.household
              ).typeName
            },
            canCreateCaregiverOrCleanerGroup() && {
              name: "caregiver",
              placeholder: clientController.findGroupTypeById(
                groupTypeIds.caregiver
              ).typeName
            },
            {
              name: "handyman",
              placeholder:
                clientController.findGroupTypeById(groupTypeIds.handyman)
                  .typeName + " Service"
            },
            canCreateCaregiverOrCleanerGroup() && {
              name: "cleaner",
              placeholder: "Cleaning Service"
            },
            {
              name: "transportation",
              placeholder:
                clientController.findGroupTypeById(groupTypeIds.transportation)
                  .typeName + " Service"
            }
          ].filter(Boolean),
          value: "careCircle",
          placeholder: "Set up a"
        }
      ],
      onSubmit: async form => {
        const selected = form[0] || {},
          { value } = selected;
        if (!value) {
          return Promise.reject({
            message: UIText.comingSoon
          });
        }
        if (value === "careCircle") {
          return (stateController.initSetupMode = 3.1);
        }
        if (value === "household") {
          stateController.initSetupMode = "household";
        }
        if (value === "caregiver") {
          stateController.initSetupMode = 9.1;
        }
        if (value === "cleaner") {
          stateController.initSetupMode = "cleaner";
        }
        if (value === "handyman") {
          stateController.initSetupMode = "handyman";
        }
        if (value === "transportation") {
          stateController.initSetupMode = "transportation";
        }
      }
    }
  ],
  onFinishSetup: props => {
    props.resetSetup();
  }
});
