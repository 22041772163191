import { Linking, Platform } from "react-native";
import { storage } from "../../utils/storage";
import { action, autorun, computed, observable, reaction } from "mobx";
import { apiService } from "../services/api-service";
import {
  asyncPause,
  capitalize,
  flipCoin,
  getCookie,
  getQueryParameters,
  isEmpty,
  isEqual,
  minutesToMilli,
  randomString,
  safeParseJSON,
  whenFulfill
} from "../../utils/helpers";
import { stateController } from "./state-controller";
import { apiController } from "./api-controller";
import { txConfig } from "../../config/tx-config";
import NavigationService from "../../utils/navigation-service";
import { UIText } from "../../config/lang-config";
import {
  disabledConsole,
  restoreConsole
} from "../../utils/env-adjustment-loader";
import {
  consoleEnabledUsers,
  env,
  invitationStatus,
  memberStatus,
  RoleGroupGroupTypeIds,
  rootDomain,
  termsUrl
} from "../../config/variable-config";
import {
  endpointConfig,
  serverConfig,
  tokenIdleTimeout
} from "../../config/api-config";

class ClientController {
  @observable deviceId;
  @observable client = {};
  @observable initialized = false;
  @observable subscriptionReady = true;
  @observable invitationsReady = false;

  @observable baseSwitched = false;
  baseSwitchTimeout;
  baseSwitchCount = 0;

  isEmbedded;
  isSyncMode;
  preventLoading;

  // Controls app global login state.
  @computed get loginState() {
    return (
      !!this.client.oid &&
      !!getCookie(`${env}oid`) &&
      !!this.client.user &&
      !!this.defaultGroup &&
      !!this.defaultGroup.profile &&
      !!this.defaultMember &&
      !!this.defaultMember.profile &&
      !!this.client.user.members &&
      Array.isArray(this.client.groups)
      // !!this.subscriptionReady
    );
  }

  @computed get clientId() {
    return this.client.id;
  }
  @computed get userId() {
    return this.client.user && this.client.user.id;
  }
  @computed get defaultMember() {
    return (this.client.user && this.client.user.defaultMember) || {};
  }
  @computed get defaultGroup() {
    return (this.client.user && this.client.user.defaultGroup) || {};
  }
  @computed get scratchpadId() {
    return this.client.scratchpadId;
  }

  @computed get isVisitor() {
    return (
      this.client.user &&
      this.client.user.userName &&
      this.client.user.userName.match(/{visitor}/g)
    );
  }

  // constructor(Store) {
  //   this.client = new Store();
  //   console.log(this.client);
  // }

  // Entry point of client.
  constructor() {
    this.checkEmbeddedModes()
      .then(() => {
        // Do not initialize client when only in sync mode or loading demo mode.
        const isHardLoading = !!(getQueryParameters(window.location.search) ||
          {})["strict_loading"];
        if (isHardLoading) return;

        if (this.isSyncMode) {
          return this.checkBaseSwitch()
            .then(this.genDeviceId)
            .then(this.isMaintenance)
            .then(this._loadClient);
        }
        if (this.preventLoading) {
          return;
        }
        // Full initialization
        return this.checkBaseSwitch()
          .then(this.genDeviceId)
          .then(this.isMaintenance)
          .then(this._loadClient)
          .then(this.isFirstTime)
          .then(this.checkIdle);
      })
      .catch(console.warn);
  }

  static flavorOAuth2Data = (oauth, id) => {
    if (isEmpty(oauth)) return "";
    oauth.timestamp = new Date().getTime();
    const raw = JSON.stringify(oauth);
    const salted = flipCoin() ? `${raw}${id}` : `${id}${raw}`;
    return btoa(salted);
  };

  static deflavorOAuth2Data = (cooked, id) => {
    cooked = cooked || "";
    try {
      return safeParseJSON(atob(cooked).replace(id, ""), true);
    } catch (e) {
      return {};
    }
  };

  registerEmbeddedStatus = (isEmbedded, isSyncMode) => {
    this.gotEmbeddedModes = true;
    this.isEmbedded = isEmbedded;
    this.isSyncMode = isSyncMode;
  };

  checkEmbeddedModes = async () =>
    await whenFulfill(() => this.gotEmbeddedModes);

  checkBaseSwitch = async () =>
    storage
      .load({
        key: "baseSwitch"
      })
      .then(baseUrl => {
        if (baseUrl) {
          apiService.baseSwitch(baseUrl);
          txConfig.baseSwitch(baseUrl);
          this.baseSwitched = true;
        }
      })
      .catch(console.warn);

  genDeviceId = async () => {
    const deviceId = await storage
      .load({
        key: "uid"
      })
      .catch(err => {
        console.warn(err.message);
        return null;
      });
    if (!deviceId) {
      const cookieDeviceId = getCookie(`${env}device`);
      if (!cookieDeviceId) {
        this.deviceId = randomString();
        return storage.save({
          key: "uid",
          data: this.deviceId,
          expires: null
        });
      }
      this.deviceId = cookieDeviceId;
      return storage.save({
        key: "uid",
        data: this.deviceId,
        expires: null
      });
    }
    this.deviceId = deviceId;
  };

  isFirstTime = async () => {
    if (this.loginState) return;

    const showPopup = () =>
      stateController.currentScreen === "Login" &&
      stateController.showPopup({
        title: UIText.title,
        content: UIText.welcomeMessage,
        leftButtonText: UIText.generalYes
      });

    return await storage
      .load({
        key: "exist"
      })
      .then(exist => {
        if (!exist) return showPopup();
      })
      .catch(err => {
        console.warn(err.message);
        return showPopup();
      })
      .finally(() =>
        storage.save({
          key: "exist",
          data: true,
          expires: null
        })
      );
  };

  isMaintenance = async () => {
    if (this.baseSwitched) return;

    return apiController
      .getMaintenanceState()
      .then(message => {
        if (message) {
          this.execLogout(null, true);
          return stateController.dismissPopup().then(() =>
            Promise.reject(
              stateController.showPopup({
                title: UIText.title,
                content: message,
                locked: true
              })
            )
          );
        }
        return Promise.resolve();
      })
      .catch(() =>
        Promise.reject(
          stateController.showPopup({
            title: UIText.title,
            content: UIText.serviceUnavailable,
            locked: true
          })
        )
      );
  };

  _loadClient = async () => {
    const error = err => {
      console.warn(err.message);
      this._registerAutorun();

      if (err && err.response) this.execLogout();

      return (this.initialized = true);
    };

    // Check local storage for existing client data object.
    return await storage
      .load({
        key: "client"
      })
      .then(client => {
        if (client) this.client = client;

        this._registerAutorun();

        if (this.loginState || !isEmpty(this.client.oauth)) {
          if (this.isSyncMode) return this.backgroundSyncModeReset();
          if (this.loginState) apiService.updateOAuthData(this.client.oauth);
          return !txConfig.fullResetOnLaunch && this.loginState
            ? this.cachedClientReset()
            : this.fullClientReset();
        }

        return (this.initialized = true);
      })
      .catch(error);
  };

  setOAuthGetter = () =>
    Object.defineProperty(this.client, "oauth", {
      get: () => {
        const oid = getCookie(`${env}oid`);
        if (this.client.oid !== oid) this.client.oid = oid;
        return ClientController.deflavorOAuth2Data(oid, this.deviceId);
      }
    });

  setDeviceIdCookie = () =>
    (document.cookie = `${env}device=${
      this.deviceId
    }; domain=.${rootDomain}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/; secure; samesite=lax`);

  setVisitorCookie = () =>
    (document.cookie = `${env}visitor=${
      this.isVisitor
    }; domain=.${rootDomain}; expires=${
      this.isVisitor
        ? "Fri, 31 Dec 9999 23:59:59 GMT"
        : new Date().toUTCString()
    }; path=/; secure; samesite=lax`);

  /**
   ** Automation
   */
  _registerAutorun = () => {
    this.disposers = [
      this._storageListener(),
      this._deviceIdListener(),
      this._oauthGetter(),
      this._oauthDataListener(),
      this._visitorListener(),
      this._roleMemberGetterListener(),
      this._actorMemberGetterListener(),
      this._wpSubscriptionGetterListener()
      // this._profileFixerListener()
    ];
  };

  // On every client data change, write to local storage.
  _storageListener = () =>
    autorun(() => {
      return storage
        .save({
          key: "client",
          data: this.client,
          expires: null
        })
        .catch(console.warn);
    });

  _deviceIdListener = () => autorun(this.setDeviceIdCookie);

  _oauthGetter = () => autorun(this.setOAuthGetter);

  // Updates OAuth2 data into apiService on OAuth2 data update
  _oauthDataListener = () => {
    const _reaction = () => {
      this.client.oauth && apiService.updateOAuthData(this.client.oauth);
      apiService.registerLogoutHandler((event, resetUrl) =>
        this.execLogout(event, resetUrl)
      );
      apiService.registerRenewHandler(async refreshToken =>
        this.renewOAuth2Data(refreshToken)
      );
    };
    _reaction();
    return reaction(() => this.client.oid, _reaction);
  };

  _visitorListener = () => autorun(this.setVisitorCookie);

  // Add a members getter to every role instance of a group.
  _roleMemberGetterListener = () =>
    autorun(() => {
      if (!Array.isArray(this.client.groups)) return;
      for (let group of this.client.groups) {
        if (!Array.isArray(group.roles)) continue;
        for (let role of group.roles) {
          Object.defineProperty(role, "members", {
            configurable: true,
            get: () =>
              (Array.isArray(role.memberIdList) &&
                role.memberIdList.map(mId =>
                  clientController.findMemberById(mId)
                )) ||
              []
          });
        }
      }
    });

  // Add a members getter to every actor instance of a topic.
  _actorMemberGetterListener = () =>
    autorun(() => {
      if (!Array.isArray(this.client.topics)) return;
      for (let topic of this.client.topics) {
        if (!Array.isArray(topic.actors)) continue;
        for (let actor of topic.actors) {
          Object.defineProperty(actor, "members", {
            configurable: true,
            get: () =>
              (Array.isArray(actor.memberIdList) &&
                actor.memberIdList.map(mId =>
                  clientController.findMemberById(mId)
                )) ||
              []
          });
        }
      }
    });

  // Add a WP subscription getter to every group.
  _wpSubscriptionGetterListener = () =>
    autorun(() => {
      if (!Array.isArray(this.client.groups)) return;
      for (const group of this.client.groups) {
        Object.defineProperty(group, "wpSubscription", {
          configurable: true,
          get: () => clientController.findGroupWpSubscriptionById(group.id)
        });
      }
    });

  /**
   ** Loading Sequence Staple Functions.
   */
  execSignIn = async fieldData => {
    // Fail safe logout.
    this.execLogout(null, null, true);
    apiService.hardResetPending();

    // TODO: For multi-device based auth, give clientId ahead from postLogin.
    // fieldData.clientId = randomString();

    return this.isMaintenance()
      .then(() => apiController.login(fieldData))
      .then(this.updateOAuth2Data);
  };

  renewOAuth2Data = async refreshToken => {
    if (!this.initialized || (!refreshToken && !this.loginState)) return;

    const OAuth2Data = refreshToken && {
      refresh_token: refreshToken
    };

    if (this.isVisitor) {
      return apiService
        .async("POST", {
          renewingOAuth2Data: true,
          headers: serverConfig.defaultHeaders,
          endpoint: endpointConfig.visitor_renew_token,
          data: {
            email: this.client.user.email,
            refreshToken: (OAuth2Data || this.client.oauth)["refresh_token"]
          }
        })
        .then(response => {
          const { oauth } = response.data || {};
          return oauth || {};
        })
        .then(this.updateOAuth2Data);
    }

    return apiController
      .renewToken(OAuth2Data || this.client.oauth)
      .then(this.updateOAuth2Data);
  };

  registerClient = async () => {
    const clientId = this.clientId || randomString();

    // Before adding any of the UA plugin, will use OS for now.
    const userAgent = Platform.OS;

    const postData = {
      clientId,
      userAgent
    };

    this.client.id = clientId;

    return apiController.postLogin(postData).then(user => {
      if (isEmpty(user)) {
        this.execLogout();
        return Promise.reject({ message: "Received invalid credentials." });
      }

      if (user.password) delete user.password;

      if (env === "prod") {
        (consoleEnabledUsers.includes(user.userName)
          ? restoreConsole
          : disabledConsole)();
      }

      // if (credPersistUsers.includes(user.userName)) {
      //   apiController.userCredPersist = true;
      // }

      this.client.user = user;

      return Promise.resolve();
    });
  };

  getUserMe = async () => apiController.getUserMe(true);

  getGroupTypes = async () => {
    return apiController
      .getGroupTypes()
      .then(groupTypes => (this.client.groupTypes = groupTypes));
  };

  getDefaultMemberAndGroup = async () => {
    return Promise.all([
      apiController
        .getDefaultGroup()
        .then(
          group => this.client.user && (this.client.user.defaultGroup = group)
        ),
      apiController
        .getDefaultMember()
        .then(
          member =>
            this.client.user && (this.client.user.defaultMember = member)
        )
    ]);
  };

  getUserGroupsAndMembers = async () => {
    return Promise.all([
      apiController.getUserGroups().then(groups => {
        !Array.isArray(this.client.groups) && (this.client.groups = []);
        for (let group of groups) {
          if (isEmpty(this.findGroupById(group.id))) {
            this.client.groups.push(group);
          } else {
            this.updateGroup(group);
          }
        }
      }),
      apiController.getUserMembers().then(members => {
        this.client.user && (this.client.user.members = members);
        for (let member of members) {
          if (member.profile) this.updateProfile(member.profile);
        }
      })
    ]);
  };

  // getGroupSubscriptions = async () => {
  //   // TODO: Until we have a one shot subscription getter endpoint, deferring this to the moment of rendering.
  //   // for (let group of this.client.groups) {
  //   //   if (isEmpty(group.subscription)) {
  //   //     await checkGroupSubscription(group.id)
  //   //   }
  //   // }
  //   return Promise.resolve();
  // };

  getUserWpSubscriptions = async includeProduct => {
    if (this.isVisitor) return (this.subscriptionReady = true);
    return apiService
      .async("GET", {
        endpoint: endpointConfig.subscriptio_active_subscriptions(
          includeProduct
        )
      })
      .then(response => (this.client.user.wpSubscriptions = response.data))
      .then(() => (this.subscriptionReady = true));
  };

  getUserInvitations = async () => {
    return await apiController.getUserInvitations().then(invitations => {
      invitations = invitations.filter(
        inv => !!this.client.user.members.find(m => m.id === inv.memberId)
      );
      this.client.invitations = invitations;
      this.invitationsReady = true;
      return Promise.resolve();
    });
  };

  // getGroupMembersProfile = async () => {
  //   for (let group of this.client.groups) {
  //     for (let member of group.members) {
  //       if (member.profile && member.profile.data) continue;
  //
  //       await apiController.getProfileById(member.profileId).then(profile => {
  //         member.profile = profile || {};
  //       });
  //     }
  //   }
  //   return Promise.resolve();
  // };

  getInitialDataSync = async () =>
    Promise.all([
      this.getGroupTypes(),
      this.getDefaultMemberAndGroup(),
      this.getUserGroupsAndMembers(),
      this.getUserWpSubscriptions()
    ]);

  /**
   **  ClientController Data Update Functions, local CDO
   **/
  updateOAuth2Data = async oauth => {
    if (isEmpty(oauth)) {
      return Promise.reject({ message: "Received invalid credentials." });
    }

    const oid = ClientController.flavorOAuth2Data(oauth, this.deviceId);
    document.cookie = `${env}oid=${oid}; domain=.${rootDomain}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; secure; samesite=lax`;
    this.client.oid = oid;

    // Update token information to api middleware headers.
    // apiService.updateOAuthData(oauth);
    // Store token information to local storage.
    // this.client["oauth"] = {
    //   ...oauth,
    //   timestamp: new Date().getTime()
    // };

    return Promise.resolve();
  };

  updateUser = data => {};

  updateGroup = action((data, id) => {
    const groupId = id || (data && data.id);
    if (!groupId || !data || !this.loginState) return;

    const update = {
      group: this.findGroupById(groupId)
    }; // Do a reference to the actual data using object property.

    if (isEmpty(update.group)) {
      return this.client.groups.push(data);
    } // Group has been confirmed is self group during transaction service handler.

    if (Array.isArray(data.members) && Array.isArray(update.group.members)) {
      for (let member of data.members) {
        if (!member) continue;
        const oMember = update.group.members.find(m => m.id === member.id);
        const profile = oMember && oMember.profile;
        if (isEmpty(member.profile) && profile) member.profile = profile;
        const roleList = oMember && oMember.roleList;
        if (isEmpty(member.roleList) && roleList) member.roleList = roleList;
      }
    }

    /* Forget about comparing because this is likely to be different anyway */
    // // Render is expensive, so skip on same values.
    // if (isEqual(update.group, data)) return;

    // Use patch style update to prevent data error.
    return (update.group = Object.assign(update.group, data));
  });

  updateMember = action((data, id) => {
    const memberId = id || (data && data.id);
    if (!memberId || !data || !this.loginState) return;

    const member = data;

    const update = {
      members: this.findMembers(m => m.id === memberId),
      own: this.client.user.members.find(m => m.id === memberId)
    }; // Do a reference to the actual data using object property.

    if (update.members.length > 0) {
      // Use patch style update to prevent data error.
      for (let mbr of update.members) {
        mbr = Object.assign(mbr, member);
      }
    } else {
      // Push new member into corresponding group, if group exist;
      const group = this.findGroupById(member.groupId) || {};
      Array.isArray(group.members) && group.members.push(member);
    }

    if (update.own) {
      update.own = Object.assign(update.own, member);
    }
  });

  updateTopic = action((data, id) => {
    const topicId = id || (data && data.id);
    if (!topicId || !data || !this.loginState) return;

    !Array.isArray(this.client.topics) && (this.client.topics = []);

    const update = { topic: this.findTopicById(topicId) };

    if (isEmpty(update.topic)) {
      return this.client.topics.push(data);
    }

    // Render is expensive, so skip on same values.
    if (isEqual(update.topic, data)) return;

    // Use patch style update to prevent data error.
    return (update.topic = Object.assign(update.topic, data));
  });

  updateProfile = action((data, id) => {
    const profileId = id || (data && data.id);
    if (!profileId || !data || !this.loginState) return;

    const profile = data;

    const pGroups = this.findGroups(g => g.profileId === profileId);
    const members = this.findMembers(m => m.profileId === profileId);

    for (let group of pGroups) {
      group.profile = profile;
    }
    for (let member of members) {
      member.profile = profile;
    }
    if (this.defaultMember.profileId === profileId) {
      this.client.user.defaultMember.profile = profile;
    }
    if (this.defaultGroup.profileId === profileId) {
      this.client.user.defaultMember.profile = profile;
    }
  });

  updateSubscription = action((data, groupId) => {
    if (!groupId || !data) return;

    const update = { group: this.findGroupById(groupId) };

    if (isEmpty(update.group)) return;

    if (isEqual(update.group.subscription, data)) return;

    return (update.group.subscription = data);
  });

  /**
   ** ClientController Remove Functions, Local CDO
   **/
  removeGroup = action(group => {
    if (!group) return;

    const groupId = typeof group === "number" ? group : group.id;

    const remove = this.findGroupById(groupId);

    if (!isEmpty(remove)) {
      // const groupName = `${toJS(remove)["groupTypeName"]} ${getDisplayNameEng(
      //   toJS(remove).profile
      // )}`;
      this.client.groups.remove(remove);
      // return stateController.showPopup({
      //   title: capitalize(UIText.group),
      //   content: UIText.groupDeleted(groupName),
      //   leftButtonText: UIText.generalConfirm,
      //   locked: true
      // });
    }
  });

  removeMember = action(member => {
    if (!member) return;

    const memberId = typeof member === "number" ? member : member.id;

    const remove = this.findMemberById(memberId, "group");

    if (isEmpty(remove)) return;

    if (remove.userId === this.userId) {
      const selfMember =
        Array.isArray(this.client.user.members) &&
        this.client.user.members.find(m => m.id === memberId);

      selfMember && this.client.user.members.remove(selfMember);

      return this.removeGroup(remove.groupId);
    }

    const group = this.findGroupById(remove.groupId);

    if (group) {
      return group.members.remove(remove);
    }
  });

  removeTopic = action(topic => {
    if (!topic) return;

    const topicId = typeof topic === "number" ? topic : topic.id;

    const remove = this.findTopicById(topicId);

    if (!isEmpty(remove)) {
      return this.client.topics.remove(remove);
    }
  });

  /**
   **  ClientController Utility Functions
   **/
  checkInvitedMembers = async () => {
    const invitedMembers = this.client.invitations
      .map(
        inv =>
          Number(inv["invitationStatus"] === invitationStatus.pendingAccept) &&
          this.client.user.members.find(m => m.id === inv.memberId)
      )
      .filter(Boolean)
      .filter(m => Number(m.status) === memberStatus.invited);

    const visibleGroups = this.findVisibleGroups(); // Determine this is a setup user or new user.
    const hasRoleGroup = visibleGroups.some(g =>
      RoleGroupGroupTypeIds.includes(g.typeId)
    );

    if (invitedMembers.length === 0) {
      return Promise.resolve(false);
    }

    if (visibleGroups.length > 0) {
      stateController.dismissPopup().then(() =>
        stateController.showPopup({
          title: capitalize(UIText.invitation),
          content: UIText.invitationPending(hasRoleGroup),
          leftButtonText: UIText.generalNo,
          leftButtonPress: () => {
            stateController.unlockPopup();
            stateController
              .dismissPopup()
              .then(() =>
                NavigationService.navigate(
                  stateController.currentScreen === "Root"
                    ? "Group"
                    : stateController.currentScreen
                )
              );
          },
          rightButtonText: UIText.generalYes,
          rightButtonPress: () => {
            stateController.unlockPopup();
            stateController.initSetupMode = "accept_invite";
            NavigationService.navigate("Setup");
            return stateController.dismissPopup();
          },
          dismissOnBackPress: false,
          locked: true
        })
      );
      return Promise.resolve(true);
    } else {
      stateController.initSetupMode = "accept_invite";
      const waitNavigator = () => {
        if (!NavigationService.isReady()) {
          return setTimeout(waitNavigator, 500);
        } else {
          return NavigationService.navigate("Setup");
        }
      };
      waitNavigator();
      return Promise.resolve(true);
    }
  };

  /*
    Finders, these methods help find data in local storage.
   */
  /**
    TODO: The finder functions should provide passive async API calls to refresh the data upon every* local data being requested.
   **/
  findGroupById = groupId => {
    const groups = this.client.groups || [];
    return groups.find(group => group.id === groupId) || {};
  };

  findGroupTypeById = groupTypeId => {
    const groupTypes = this.client.groupTypes || [];
    return groupTypes.find(gt => gt.id === groupTypeId) || {};
  };

  findGroups = query => {
    const groups = this.client.groups;
    if (Array.isArray(groups)) {
      return groups.filter(query);
    }
    return [];
  };

  findGroupWpSubscriptionById = (groupId, includeCancelExpire) => {
    const subscriptions = this.client.user.wpSubscriptions || [];
    const wpSubscription = subscriptions.find(
      sub => (sub["subscriptionGroup"] || {}).groupId === groupId
    );
    const valid = ((wpSubscription || {}).status || "").match(
      /active|paused|setToCancel/gi
    );
    return (valid || includeCancelExpire) && wpSubscription;
  };

  findVisibleGroups = includeScratchpad => {
    if (!this.client.user) return [];

    const status3GroupIds = Array.isArray(this.client.user.members)
      ? this.client.user.members
          .filter(m => Number(m.status) === memberStatus.invited)
          .map(member => member.groupId)
      : [];

    const groups = this.client.groups;

    return Array.isArray(groups)
      ? groups.filter(
          group =>
            group.typeId > 0 &&
            !status3GroupIds.includes(group.id) &&
            (!group.groupName ||
            !group.groupName.match(/{scratchpad}/g) ||
            includeScratchpad
              ? true
              : group.groupName.match(/{scratchpad}/g) &&
                (!isEmpty(group.subscription) ||
                  !isEmpty(group.wpSubscription)))
        )
      : [];
  };

  findMemberById = memberId => {
    const members = this.findMembers(m => m.id === memberId);
    return members[0] || {};
  };

  findMembers = (query, limit) => {
    let members = [];
    const groups = this.client.groups;
    const topics = this.client.topics;
    if (Array.isArray(groups) && limit !== "topic") {
      for (let group of groups) {
        const m = Array.isArray(group.members) && group.members.filter(query);
        if (m) members.push(...m);
      }
    }
    if (Array.isArray(topics) && limit !== "group") {
      for (let topic of topics) {
        const m = Array.isArray(topic.members) && topic.members.filter(query);
        if (m) members.push(...m);
      }
    }
    return members;
  };

  findTopicById = topicId => {
    const topics = this.client.topics || [];
    if (!topicId || !Array.isArray(topics)) return false;
    return topics.find(t => t.id === topicId) || {};
  };

  findSubTopicByParentId = parentId => {
    return clientController.findTopics(t => t.parentId === parentId);
  };

  findTopics = query => {
    const topics = this.client.topics;
    if (Array.isArray(topics)) {
      return topics.filter(query);
    }
    return [];
  };

  // findProfileById = profileId => {
  //   const profiles = this.client.profiles;
  //   if (!profileId || !Array.isArray(profiles)) return false;
  //   return profiles.find(p => p.id === profileId) || {};
  // };
  //
  // findProfiles = query => {
  //   const profiles = this.client.profiles;
  //   if (Array.isArray(profiles)) {
  //     return profiles.filter(query);
  //   }
  //   return [];
  // };

  /*
    Pure utilities, free and fun to use.
   */
  clearUser = async () => (this.client.user = {});

  clearGroups = async () => (this.client.groups = []);

  clearGroupTypes = async () => (this.client.groupTypes = []);

  clearTopics = async () => (this.client.topics = []);

  clearMessages = async () => (this.client.messages = {});

  clearTypeClasses = async () => (this.client.typeClasses = []);

  clearInvitations = async () => (this.client.invitations = []);

  clearStorage = () => {
    // MobX autorun disposer.
    this._storageListener();
  };

  setLastTxId = txId => {
    if (!txId) return;
    if (this.client.user && this.client.user.txId) this.client.user.txId = txId;
  };

  setScratchpadId = id => (this.client.scratchpadId = id);

  showVisitorTncBanner = () => (this.client.visitorTncBannerVisible = true);

  dismissVisitorTncBanner = () => (this.client.visitorTncBannerVisible = false);

  initializeClient = async () => {
    this.subscriptionReady = false;
    this.invitationsReady = false;

    const start = new Date().getTime();

    return this.clearUser()
      .then(this.clearGroups)
      .then(this.clearGroupTypes)
      .then(this.clearMessages)
      .then(this.clearTopics)
      .then(this.clearTypeClasses)
      .then(this.clearInvitations)
      .then(this.registerClient)
      .then(this.getInitialDataSync)
      .then(this.getUserInvitations)
      .then(this.checkInvitedMembers)
      .finally(() => {
        const end = new Date().getTime();
        console.log(end - start, "ms");
      });
  };

  fullClientReset = async () => {
    this.initialized = false;
    return stateController
      .showPopup({
        title: UIText.clientLoading,
        content: UIText.pleaseWait
      })
      .then(this.initializeClient)
      .finally(() => {
        this.initialized = true;
        return stateController.dismissPopup();
      });
  };

  cachedClientReset = async () =>
    stateController
      .showPopup({
        title: UIText.clientLoading,
        content: UIText.pleaseWait,
        locked: true
      })
      .then(this.getUserInvitations)
      .then(this.checkInvitedMembers)
      .finally(() => {
        this.initialized = true;
        return stateController.dismissPopup();
      });

  backgroundSyncModeReset = async () => {
    this.initialized = false;
    return this.getUserMe().finally(() => {
      this.initialized = true;
    });
  };

  baseSwitch = event => {
    clearTimeout(this.baseSwitchTimeout);
    this.baseSwitchTimeout = setTimeout(() => (this.baseSwitchCount = 0), 350);
    this.baseSwitchCount++;
    const makeSwitch = (hostname, port, https) => {
      const baseUrl = `${https ? "https://" : "http://"}${hostname}:${port}`;

      apiService.baseSwitch(baseUrl);
      txConfig.baseSwitch(baseUrl);
      storage
        .save({
          key: "baseSwitch",
          data: baseUrl,
          expires: null
        })
        .catch(console.warn);

      this.baseSwitched = true;

      return stateController.showPopup({
        title: "Axios",
        content: `baseURL switched to ${baseUrl}`,
        leftButtonText: UIText.generalConfirm,
        leftButtonPress: e => this.execLogout()
        // onBackdropPress:  e => this.execLogout(),
      });
    };
    if (env !== "prod" && this.baseSwitchCount >= 5) {
      // to local
      const hostname = "localhost";
      const port = 8081;
      const https = false;
      makeSwitch(hostname, port, https);
    }
    if (env !== "prod" && this.baseSwitchCount >= 10) {
      const hostname = "test.mycarebase.com";
      const port = 443;
      const https = true;
      makeSwitch(hostname, port, https);
    }
    if (env !== "prod" && event.prod) {
      const hostname = "prod.mycarebase.com";
      const port = 443;
      const https = true;
      makeSwitch(hostname, port, https);
    }
  };

  clearBaseSwitch = () =>
    storage
      .save({
        key: "baseSwitch",
        data: null,
        expires: null
      })
      .then(this.execLogout)
      .then(() => window.location.reload())
      .catch(console.warn);

  openAgreement = () => {
    if (Platform.OS === "web") {
      const termsWindow = window.open("/?showTerms=true");
    } else {
      return Linking.openURL(termsUrl);
    }
  };

  setFilterController = filterController =>
    (this.filterController = filterController);

  checkIdle = async () => {
    const showPopup = () =>
      stateController
        .showPopup({
          title: UIText.title,
          content: UIText.idleTimeout,
          leftButtonText: UIText.generalConfirm,
          dismissOnBackPress: true
        })
        .catch(console.warn);
    if (this.initialized && this.loginState) {
      const oauthTimestamp = ((this.client || {}).oauth || {}).timestamp;
      const noRenew =
        oauthTimestamp &&
        new Date().getTime() - oauthTimestamp >=
          tokenIdleTimeout * (this.client.user.isSa ? 2 : 1);
      await apiService
        .async("GET", {
          endpoint: endpointConfig.user,
          noRenew,
          noSpinner: true
        })
        .then(responseOrIs401 => {
          if (!responseOrIs401.data && responseOrIs401 === true)
            return showPopup();
        });
    }
    await asyncPause(minutesToMilli(1));
    return this.checkIdle();
  };

  execLogout = (event, resetUrl, exclPopup) => {
    let logoutCalled = false;

    const logout = () => {
      if (apiService.pending > 0 && event) {
        !logoutCalled && setTimeout(apiService.hardResetPending, 5000);
        logoutCalled = true;
        return setTimeout(logout, 200);
      }

      document.cookie = `${env}oid=; domain=.${rootDomain}; path=/; secure; samesite=lax`;

      this.client = {};
      this.subscriptionReady = false;
      this.invitationsReady = false;

      apiService.resetOAuthData();
      stateController.reset(exclPopup);
      this.filterController && this.filterController.reset();

      if (env === "prod") {
        disabledConsole();
      }

      if (resetUrl) {
        window.history &&
          window.history.pushState &&
          window.history.pushState("", "", "/");
      }
      if (event) {
        NavigationService.navigate("Login");
      }
    };

    event
      ? stateController
          .showPopup({
            title: UIText.logoutProgress,
            content: UIText.pleaseWait
          })
          .then(logout)
      : logout();
  };
}

// const clientController = new ClientController(ClientStore);
const clientController = new ClientController();

// For development;
if (window && env !== "prod") window.clientController = clientController;

export { clientController };
